<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('headquarters.hqTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <!-- Basic Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.basicInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-name">
              <span>{{ $t('headquarters.hqName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-name"
                          v-model="form.hqName"
                          :class="{ 'is-invalid': submitted && $v.form.hqName.$error }" />
            <div v-if="submitted && $v.form.hqName.$error" class="invalid-feedback">
              <span v-if="!$v.form.hqName.required">{{ $t('validation.hqNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-busi-no">{{ $t('headquarters.businessNo') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-busi-no"
                          v-model="form.businessNo" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-tel">
              <span>{{ $t('headquarters.tel') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-tel"
                          v-model="form.tel"
                          :class="{ 'is-invalid': submitted && $v.form.tel.$error }" />
            <div v-if="submitted && $v.form.tel.$error" class="invalid-feedback">
              <span v-if="!$v.form.tel.required">{{ $t('validation.busiCallIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-8">
            <label class="form-label" for="hq-update-address">
              <span>{{ $t('headquarters.address') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-address"
                          v-model="form.address"
                          :class="{ 'is-invalid': submitted && $v.form.address.$error }" />
            <div v-if="submitted && $v.form.address.$error" class="invalid-feedback">
              <span v-if="!$v.form.address.required">{{ $t('validation.addressIsRequired') }}</span>
            </div>
          </div>
        </div>
        <!-- Basic Info End -->

        <!-- Plan Info Start -->
        <div class="ion-modal-form-group-title">
          {{ $t('headquarters.planInfo') }}
          <span class="ps-2 font-size-14 fw-normal text-danger">* {{ $t('common.planWarning') }}</span>
        </div>
        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-plan">
              <span>{{ $t('headquarters.planName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <!-- TODO: 다국어 처리 필요, 플랜 변경 && 삭제 안됨 -->
            <multiselect id="hq-update-plan"
                         v-model="plan"
                         label="text"
                         :options="planOptions"
                         :placeholder="$t('alert.selectPlan')"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         @input="setDefaultPlanInfo" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-plan-date">{{ $t('headquarters.planDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="form.planDate"
                           value-type="format"  />
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-plan-edit-date">{{ $t('plan.planEditDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="item.settleInfoUpdateTimestamp"
                           value-type="format"  disabled="disabled" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-server-fee">{{ $t('headquarters.serverUsageFee') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-server-fee"
                          v-model="form.serverUsageFee"
                          :disabled="$_app.user.roles[0] !== 'ADMIN'" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-settlement-rate">{{ $t('headquarters.settleRate') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-settlement-rate"
                          v-model="form.settleRate"
                          :disabled="$_app.user.roles[0] !== 'ADMIN'" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-update-profit-dist-rate">{{ $t('headquarters.profitDistribution') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-profit-dist-rate"
                          :disabled="plan?.value === 10000000000224 || $_app.user.roles[0] !== 'ADMIN'"
                          v-model="form.commissionRate" />
          </div>
        </div>
        <!-- Plan Info End -->

        <!-- Settlement Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.settlementInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-bank">{{ $t('headquarters.bankName') }}</label>
            <b-form-input class="form-control" type="text" id="hq-update-bank" v-model="form.bank" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-settlement-day">{{ $t('headquarters.settleDay') }}</label>
            <div class="row">
              <div class="col-2 text-center align-content-center">{{ $t('headquarters.monthly') }}</div>
              <div class="col-4">
                <b-form-input class="form-control" type="text" id="hq-update-settlement-day" v-model="form.settleDay" />
              </div>
              <div class="col-6 text-start align-content-center">{{ $t('headquarters.day') }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-account-holder">{{ $t('headquarters.accountHolder') }}</label>
            <b-form-input class="form-control" type="text" id="hq-update-account-holder" v-model="form.bankAccountHolder" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-account-number">{{ $t('headquarters.accountNumber') }}</label>
            <b-form-input class="form-control" type="text" id="hq-update-account-number" v-model="form.bankAccountNo" />
          </div>
        </div>
        <!-- Settlement Info End -->

        <!-- Point Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.pointInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-3">
            <label class="form-label" for="hq-update-point-use">{{ $t('headquarters.pointUseYn') }}</label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="pointUse1" autocomplete="off"
                     v-model="form.isAllowPointSaving" :value="false" @click="form.pointSavingRate = null" />
              <label class="btn btn-outline-secondary" for="pointUse1">{{ $t('common.unused') }}</label>
              <input type="radio" class="btn-check" id="pointUse2" autocomplete="off"
                     v-model="form.isAllowPointSaving" :value="true" @click="form.pointSavingRate = 0" />
              <label class="btn btn-outline-secondary" for="pointUse2">{{ $t('common.use') }}</label>
            </div>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-update-point-rate">{{ $t('headquarters.pointSaveRate') }} (%)</label>
              <b-form-input class="form-control" type="text" id="hq-update-point-rate" v-model="form.pointSavingRate" />
            </template>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-update-affiliation">{{ $t('headquarters.affiliation') }}</label>
              <b-form-input class="form-control" type="text" id="hq-update-affiliation" v-model="form.affiliationName" />
            </template>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-update-point-logo">{{ $t('headquarters.pointLogo') }}</label>

              <template v-if="savedFile">
                <div class="hstack gap-3">
                  <input class="form-control me-auto" type="text" v-model="savedFile.orgName" disabled />
                  <div class="vr"></div>
                  <button type="button" class="btn btn-outline-danger text-nowrap" @click="savedFileDel">Delete</button>
                </div>
              </template>
              <template v-else>
                <div class="d-flex">
                  <input class="form-control" type="file" accept="image/*" id="hq-update-point-logo"
                         ref="hqPointLogoInput" @change="handleFileChange" />
                  <button v-if="file" type="button" class="btn btn-outline-danger" @click="fileDel">Delete</button>
                </div>
                </template>
            </template>
          </div>
        </div>
        <!-- Point Info End -->

        <!-- Manager Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.representative') }}</div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-manager">{{ $t('headquarters.hqRepresentAccountName') }}</label>
            <!-- TODO: 다국어 처리 필요 -->
            <multiselect id="hq-update-manager"
                         v-model="manager"
                         label="text"
                         track-by="value"
                         :options="managerOptions"
                         :placeholder="$t('alert.selectManager')"
                         :searchable="false" :allow-empty="true"
                         @select="changeManager('C')"
                         @remove="changeManager('D')" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-update-manager-id">{{ $t('headquarters.hqRepresentAccountEmail') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-update-manager-id"
                          v-model="managerId"
                          disabled />
          </div>
        </div>
        <!-- Manager Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  props: {
    selectedId: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      submitted: false,
      planOptions: [],
      planDefaultInfos: null,
      managerOptions: [],
      form: {
        hqName: null,
        businessNo: null,
        tel: null,
        address: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        commissionRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
        isAllowPointSaving: false,
        pointSavingRate: null,
        affiliationName: null
      },
      file: null,
      savedFile: null,
      plan: null,
      manager: null,
      managerId: null
    }
  },
  validations: {
    form: {
      hqName: { required },
      tel: { required },
      address: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getPlanOptions();
      vm.visible = true;
    },
    getPlanOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/list';

      vm.planOptions = [];

      http.post(_url, {
        page: 1,
        limit: 50,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.planDefaultInfos = res.data.items;
        res.data.items.forEach(item => {
          vm.planOptions.push({ text: item.planName, value: item.id });
        });

        vm.getManagerOptions();
      }).then(() => {
        //vm.plan = vm.planOptions.find(ele => ele.value === 10000000000225);
        //vm.setDefaultPlanInfo();
      }).catch(err => {
        console.error('Business Plan List Error :: ', err)
      });
    },
    getManagerOptions() {
      const vm = this;
      const _url = `/onmapi/view/Account/listByHqId/${this.selectedId}`;

      vm.managerOptions = [];

      http.get(_url).then(res => {
        res.data.items.forEach(item => {
          vm.managerOptions.push({ text: item.name, value: item.id, email: item.email });
        });
        vm.getItem();
      }).catch((err) => {
        console.error('Manager List Error !!', err)
      });
    },
    changeManager(type) {
      const vm = this;

      if (type === 'C') {
        vm.managerId = vm.manager.email;
      } else if (type === 'D') {
        vm.managerId = null;
      }
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Hq/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (vm.form.chargePlacePlan && vm.form.chargePlacePlan.hasOwnProperty('id')) {
          vm.plan = vm.planOptions.find(item => item.value === vm.form.chargePlacePlan.id);
        }

        if (vm.form.hqRepresentAccount && vm.form.hqRepresentAccount.hasOwnProperty('id')) {
          vm.manager = vm.managerOptions.find(item => item.value === vm.form.hqRepresentAccount.id);
          vm.changeManager('C');
        }

        if (vm.form.logoFile) {
          vm.savedFile = vm.item.logoFile;
        }
      }).catch((err) => {
        console.error('Headquarters Retrieve Error !!', err)
      });
    },
    fileDel() {
      const vm = this;
      vm.file = null;
      this.$refs.hqPointLogoInput.value = null;
    },
    savedFileDel() {
      const vm = this;

      Swal.fire({
        title: i18n.t('alert.confirmDelete'),
        showCancelButton: true,
        confirmButtonText: i18n.t('btn.ok'),
        cancelButtonText: i18n.t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          vm.deleteImageFile();
        }
      });
    },
    deleteImageFile() {
      const vm = this;
      const _url = `/onmapi/view/Hq/deleteFile?hqId=${this.selectedId}&fileId=${vm[`savedFile`].fileId}`;

      http.delete(_url).then(res => {

        if (res.data.success === true) {
          vm.savedFile = null;
          vm.file = null;

          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.deleted'),
            confirmButtonText: i18n.t('btn.close')
          });
        }
      }).catch(err => {
        console.error('Hq Delete File Error :: ', err)
      });
    },
    handleFileChange(e) {
      const vm = this;
      const _file = e.target.files[0];

      vm.file = _file;
    },
    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    setDefaultPlanInfo() {
      const vm = this;

      const selectedPlan = vm.planDefaultInfos.find(ele => ele.id === vm.plan.value)

      vm.form = {
        ...vm.form,
        serverUsageFee : selectedPlan.serverUsageFee,
        settleRate : selectedPlan.settleRate,
        commissionRate : selectedPlan.profitDistRate,
      }
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        hqName: null,
        businessNo: null,
        tel: null,
        address: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        commissionRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
        isAllowPointSaving: false,
        pointSavingRate: null,
        affiliationName: null
      };
      vm.file = null;
      vm.savedFile = null;
      vm.plan = null;
      vm.manager = null;
      vm.managerId = null;

      vm.planOptions = [];
      vm.managerOptions = [];

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Hq/update';

      const _formData = new FormData();

      _formData.append('id', this.selectedId);
      _formData.append('hqName', vm.form.hqName);
      _formData.append('tel', vm.form.tel);
      _formData.append('address', vm.form.address);
      _formData.append('isAllowPointSaving', vm.form.isAllowPointSaving);

      const _formFields = [
        { key: 'businessNo', value: vm.form.businessNo },
        { key: 'serverUsageFee', value: vm.form.serverUsageFee },
        { key: 'settleRate', value: vm.form.settleRate },
        { key: 'commissionRate', value: vm.form.commissionRate },
        { key: 'settleDay', value: vm.form.settleDay },
        { key: 'bank', value: vm.form.bank },
        { key: 'bankAccountHolder', value: vm.form.bankAccountHolder },
        { key: 'bankAccountNo', value: vm.form.bankAccountNo }
      ];

      _formFields.forEach(field => {
        if (!this.$_utils.isEmpty(field.value)) {
          _formData.append(field.key, field.value);
        }
      });

      if (vm.form.isAllowPointSaving) {
        _formData.append('pointSavingRate', vm.form.pointSavingRate);
        _formData.append('affiliationName', vm.form.affiliationName);
      }

      if (!this.$_utils.isEmpty(vm.plan)) {
        _formData.append('planId', vm.plan.value);
      }

      if (!this.$_utils.isEmpty(vm.form.planDate)) {
        _formData.append('planDate', vm.form.planDate + ' 00:00:00');
      }

      if (!this.$_utils.isEmpty(vm.manager)) {
        _formData.append('hqRepresentAccountId', vm.manager.value);
      }

      if (!this.$_utils.isEmpty(vm.file)) {
        _formData.append('file', vm.file);
      }

      /*for (const [key, value] of _formData.entries()) {
        console.log(key, value);
      };*/

      http.patch(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Headquarters Edit Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
